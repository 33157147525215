.global-tools {
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 999999;

  .wrapper {
    width: 56px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
    border-radius: 2px;

    .icon-wrapper {
      width: 100%;
      height: 56px;
      padding: 16px;
      box-sizing: border-box;
      background: #0046FF;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      img {
        width: 100%;
      }
    }

    .name {
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 400;
      color: #1A1A1A;
      line-height: 25px;
      text-align: center;
    }
  }

  .layer {
    position: absolute;
    top: 0;
    right: 66px;

    width: 366px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
    border-radius: 2px;

    display: flex;
    align-items: center;

    transform: scaleX(0);
    transform-origin: top right;
    transition: all .5s .1s;

    .item-wrapper {
      width: 100%;
    }

    .item {
      width: 100%;
      height: 68px;

      .icon-wrapper {
        width: 68px;
        height: 100%;
        padding: 22px;
        box-sizing: border-box;

        float: left;
      }

      .content {
        height: 100%;
        float: left;
        font-size: 14px;

        line-height: 20px;
        color: #00102F;

        padding: 10px 0;
        box-sizing: border-box;

        .name {
          width: 100%;
          font-weight: 600;
        }

        .description {
          width: 100%;
          font-weight: 400;
          margin-top: 7px;
        }
      }

      &:before {
        content: '';
        display: block;
        clear: both;
      }

      &:hover {
        background: #F7FAFC;

        .content {
          .description {
            color: #0046FF;
          }
        }
      }
    }
  }

  &:hover {
    .layer {
      transform: scaleX(1);
    }
  }
}
@media screen and (max-width: 767px) {
  .global-tools {
    display: none;
  }
}
@primary-color: #2F54EB;