html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: FZLTHJW--GB1-0,FZLTHJW--GB1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


a {
  text-decoration: unset;
}

#root{
  height: 100%;
}
.page{
  min-height: calc(100% - 528px);
}
.row{
  &:after{
    display: block;
    content: '';
    clear: both;
  }
}
.gray {
  background: #F5F6F7;
}
.section {
  position: relative;
  margin: auto;
  padding: 80px 0;
  //background: #FFFFFF;
  filter: blur(0px);

  .section-container {
    max-width: 1440px;
    margin: auto;
    box-sizing: border-box;
  }

  .section-title {
    font-size: 40px;
    font-weight: 400;
    color: #00102F;
    line-height: 1.6;

    text-align: center;

  }

  .section-body {
    margin-top: 60px;
    position: relative;
  }

  &.half-top{
    padding-top: 40px;
  }
  &.half-bottom{
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1600px) {
  .section{
    .section-container{
      max-width: 1560px;
    }
  }
}
@media screen and (max-width: 1460px) {
  .section{
    .section-container{
      width: 1200px;
    }
  }
}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 992px) {

}
@media screen and (max-width: 767px) {

  html{
    font-size: calc(100vw / 750 * 100) !important;

    width: 100vw !important;
    overflow-x: hidden;
  }
  body,#root{
    width: 100vw;
    overflow-x: hidden;
  }

  .section{
    padding: 0.80rem 0;

    .section-container {
      width: 100%;
      padding: 0 0.30rem;
    }

    .section-title{
      font-size: 0.40rem;
    }

    .section-body {
      margin-top: 0.60rem;
    }

    &.half-top{
      padding-top: 0.40rem;
    }
    &.half-bottom{
      padding-bottom: 0.40rem;
    }
  }

  .page{
    width: 100vw;
    overflow-x: hidden;
    padding-top: 1.20rem;
  }
}


@primary-color: #2F54EB;