.header-wrapper {
    width: 100%;
    height: 80px;
    //line-height: 80px;
    color: #000000;
    background: #ffffff;
    font-size: 16px;

    a {
      border: none;
      text-decoration: none;
      color: inherit;

      &:hover {
        color: #0046FF !important;
      }
    }

    .header-container {

      max-width: 1440px;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .header-logo {
        display: block;
        height: 40px;
        margin-top: 20px;
        cursor: pointer;
        line-height: 100%;

        .mobile-logo{
          display: none;
        }

        img {
          height: 100%;
        }
      }

      .header-menu{
        display: none;
      }

      .header-nav {

        .header-nav-wrapper {
          height: 100%;
          line-height: 80px;
          display: flex;
          justify-content: space-between;

          &:hover {
            .header-nav-item.active {
              .header-nav-item-name:after {
                width: 0px;
                margin-left: 0px;
              }
            }
          }

          .header-nav-item {
            position: relative;
            margin-left: 40px;

            &.active {
              .header-nav-item-name {

                &:after {
                  width: 30px;
                  margin-left: -15px;
                }
              }
            }

            &:hover {
              .header-nav-item-name {


                &:after {
                  width: 30px !important;
                  margin-left: -15px !important;
                }
              }

              .header-nav-item-children-wrapper {
                height: fit-content;
              }
            }

            .header-nav-item-name {

              .header-nav-item-name-arrow{
                display: none;
              }

              &:after {
                content: '';
                position: absolute;
                width: 0px;
                height: 2px;
                background: #0046FF;
                bottom: 14px;
                left: 50%;
                transition: 0.3s;
              }
            }

            .header-nav-item-children-wrapper {
              position: absolute;
              overflow: hidden;
              transition: height 0.5s;
              width: 200px;
              height: 0;
              top: 80px;
              left: 0;
              background: #fff;
              color: #666;
              z-index: 999;

              .header-nav-item-children-item-name {
                height: 38px;
                line-height: 38px;
                padding: 0 16px;
                font-size: 14px;
                box-sizing: border-box;

                &:hover {
                  color: #597ef7;
                }
              }
            }
          }
        }
      }

      .header-tools {
        .header-tools-wrapper {
          display: flex;
          justify-content: space-between;

          .header-tools-item {
            font-size: 14px;
            font-weight: 400;
            padding: 0 13px;
            position: relative;

            &:before{
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -6.5px;
              content: '';
              display: block;
              width: 1px;
              height: 13px;
              background: rgba(0, 0, 0, 0.4);;
            }

            &:first-child{
              &:before{
                display: none;
              }
            }
          }
        }
      }
    }
  }


@media screen and (max-width: 1440px) {
  .header-wrapper {
    .header-container {
      max-width: 1320px;
    }
  }
}

@media screen and (max-width: 767px) {

  .header-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 1.20rem;
    padding: 0 0.30rem;
    box-sizing: border-box;
    overflow: visible;
    z-index: 1;
    background: black;

    .header-container{
      width: 100%;
      height: 100%;

      align-items: center;

      .header-logo{
        height: 0.40rem;
        margin-top: unset;


        .mobile-logo{
          display: block;
        }
        .pc-logo{
          display: none;
        }
      }

      .header-menu{
        display: block;
        height: 0.40rem;
        img{
          height: 100%;
        }

        .header-menu-item[name='close']{
          display: none;
        }
      }

      .header-nav{
        position: absolute;
        left: 0;
        top: 1.20rem;
        width: 100%;
        height: 0;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.9);

        transition: height 0.5s;

        .header-nav-wrapper {
          display: block;
          justify-content: unset;
          padding: 0 0.30rem;

          .header-nav-item{
            width: 100%;
            height: fit-content;
            margin-left: unset;
            border-bottom: 1px solid rgba(2, 4, 6, 0.1);

            .header-nav-item-name{
              font-size: 0.28rem;
              line-height: 1.00rem;

              display: flex;
              justify-content: space-between;

              .header-nav-item-name-arrow{
                display: inline-block;
                transition: 0.5s;
                color: rgba(11, 12, 12, 0.4);
                transform-origin: center;
                margin-right: 0.10rem;
              }
            }

            .header-nav-item-children-wrapper {
              width: 100%;
              height: 0 !important;
              position: relative;
              top:0;
              left: 0;
              background: unset;

              transition: 0.5s;

              .header-nav-item-children-item-name {
                font-size: 0.26rem;
                line-height: 0.50rem;
                height: 0.50rem;
                padding: 0 0.16rem;
                margin-bottom: 0.24rem;
              }
            }

            &.open {
              .header-nav-item-name {

                .header-nav-item-name-arrow {
                  transform: rotateZ(-180deg);
                }
              }

              .header-nav-item-children-wrapper {
                height: fit-content !important;
              }
            }
            &:hover,&.active {
              .header-nav-item-name{
                &:after {
                  display: none;
                }
              }
            }
          }

        }
      }

    }

    &.open{
      .header-menu{
        .header-menu-item[name='close']{
          display: block;
        }
        .header-menu-item[name='open']{
          display: none;
        }
      }

      .header-nav{
        height: calc(100vh - 0.120rem);
        overflow: scroll;
      }
    }

  }

}

@primary-color: #2F54EB;