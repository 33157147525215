.footer-wrapper{
  width: 100%;
  height: max-content;
  background: #1A2230;
  color: rgba(255, 255, 255, 0.4);

  a{
    color: inherit;
  }

  .footer-container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 0;

    display: flex;
    justify-content: center;
  }

  .footer-nav{
    display: flex;
    text-align: center;

    .footer-nav-item{
      margin-right: 50px;
      flex-shrink: 0;

      .nav-item-name{
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);

        .item-name-arrow{
          display: none;
        }
      }
      .nav-item-children{
        margin-top: 32px;

        .item-children-name{
          height: 40px;
          font-size: 14px;
          line-height: 40px;
        }
      }
    }
  }

  .footer-contact{
    padding-left: 24px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);

    display: flex;

    .footer-contact-item{
      width: 200px;

      .contact-item-name{
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
      }
      .contact-item-children{
        margin-top: 32px;

        font-size: 14px;
        line-height: 1.6;
      }

      &.qr-item{
        width: 120px;
        margin-left: 70px;
        .footer-qrcode{
          width: 120px;
          height: 120px;
        }
      }
    }

    .friend-link{
      margin-top: 50px;
      width: 280px;
      height: 32px;
      border: 1px solid #979797;
      position: absolute;

      display: flex;
      justify-content: space-between;

      .name{
        font-size: 14px;
        color: #979797;
        line-height: 32px;
        padding: 0 12px;
      }

      .icon-wrap{
        width: 49px;
        height: 100%;
        background: #0A35EA;
        border-left: 1px solid #979797;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          transition: all 0.5s;
        }
      }

      &.open{

        .icon-wrap{
          img{
            transform: rotateX(180deg);
          }
        }
        .children{
          height: fit-content;
        }
      }
      &:hover{
        .icon-wrap{
          img{
            transform: rotateX(180deg);
          }
        }
        .children{
          height: 300px;
        }
      }


      .children{
        width: 100%;
        height: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-100%);
        overflow: hidden;
        transition: all 0.5s;

        overflow-y: scroll;

        .item{
          width: 100%;
          height: 50px;
          font-size: 14px;
          color: #00102F;
          line-height: 50px;

          padding-left: 15px;
          box-sizing: border-box;

          &:hover{
            background: #F6FAFA;

          }
        }
      }
    }

  }

  .footer-copyright{
    width: 100%;
    height: 70px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    display: flex;
    justify-content: center;

    .copyright-item{
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      line-height: 70px;

      display: flex;

      div{
        margin-right: 16px;
      }
    }
  }


}
@media screen and (max-width: 1460px) {
  .footer-wrapper {

    .footer-container {
      max-width: 1200px;
    }

    .footer-nav{
      display: flex;
      text-align: center;

      .footer-nav-item{
        margin-right: 30px;

      }
    }

    .footer-contact{
      padding-left: 20px;

      .footer-contact-item{
        width: 200px;

        &.qr-item{
          width: 100px;
          margin-left: 30px;
          .footer-qrcode{
            width: 100px;
            height: 100px;
          }
        }
      }

      .friend-link{
        margin-top: 30px;
        width: 280px;
        height: 32px;
        border: 1px solid #979797;
        position: absolute;

        display: flex;
        justify-content: space-between;

        .name{
          font-size: 14px;
          color: #979797;
          line-height: 32px;
          padding: 0 12px;
        }

        .icon-wrap{
          width: 49px;
          height: 100%;
          background: #0A35EA;
          border-left: 1px solid #979797;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            transition: all 0.5s;
          }
        }

        &.open{

          .icon-wrap{
            img{
              transform: rotateX(180deg);
            }
          }
          .children{
            height: fit-content;
          }
        }
        &:hover{
          .icon-wrap{
            img{
              transform: rotateX(180deg);
            }
          }
          .children{
            height: 300px;
          }
        }


        .children{
          width: 100%;
          height: 0;
          background: #FFFFFF;
          box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-100%);
          overflow: hidden;
          transition: all 0.5s;

          overflow-y: scroll;

          .item{
            width: 100%;
            height: 50px;
            font-size: 14px;
            color: #00102F;
            line-height: 50px;

            padding-left: 15px;
            box-sizing: border-box;

            &:hover{
              background: #F6FAFA;

            }
          }
        }
      }

    }
  }
}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 992px) {

}

@media screen and (max-width: 767px) {
  .footer-wrapper{
    .footer-container{
      flex-direction: column;
      padding: unset;
    }

    .footer-nav{
      flex-direction: column;

      .footer-nav-item{
        margin-right: unset;
        text-align: left;

        .nav-item-name{
          height: 1.10rem;
          line-height: 1.10rem;
          font-size: 0.28rem;
          border-bottom: 0.01rem solid #282D38;

          padding: 0 0.40rem;
          display: flex;
          justify-content: space-between;

          .item-name-arrow{
            display: block;
            transition: 0.5s;
            transform-origin: center;
          }
        }

        .nav-item-children{
          height: 0;
          overflow: hidden;
          margin-top: unset;
          padding: 0 0.40rem;

          .item-children-name{
            height: 1.10rem;
            line-height: 1.10rem;
            font-size: 0.28rem;
            border-bottom: 0.01rem solid #282D38;
          }


        }

        &.open{
          .nav-item-name{
            .item-name-arrow{
              transform: rotateZ(-180deg);
            }
          }

          .nav-item-children{
            height: fit-content;
          }
        }
      }
    }

    .footer-contact{
      padding-left: unset;
      margin: 0 0.40rem;
      border-left:unset;
      padding-bottom: 0.60rem;

      justify-content: space-between;

      .footer-contact-item{
        width: 3.30rem;

        .contact-item-name{
          height: 1.10rem;
          line-height: 1.10rem;
          font-size: 0.28rem;
        }
        .contact-item-children{
          margin-top: unset;

          font-size: 0.24rem;
        }

        &.qr-item{
          width: 1.90rem;
          margin-left: 0.70rem;
          .contact-item-name{
            visibility: hidden;
          }

          .footer-qrcode{
            width: 1.90rem;
            height: 1.90rem;
          }
        }

      }

      .friend-link{
        display: none;
      }
    }

    .footer-copyright{
      width: calc(100% - 0.80rem);
      box-sizing: border-box;
      height: 1.70rem;
      margin: 0 0.40rem;
      border-top: 0.01rem solid #282D38;
      flex-direction: column;
      align-items: center;

      .copyright-item{
        font-size: 0.20rem;
        line-height: 1.6;

        div{
          margin-right: 0.10rem;
        }
      }

    }

  }
}
@primary-color: #2F54EB;